import React from "react";
// import { Link } from "gatsby";
import styled from "styled-components";
// import { CopyBlock, dracula } from "react-code-blocks";
//
import { COLORS, ELEVATION } from "../../../globals/designSystem";
import { StyledDesc1, StyledDesc2 } from "../../shared/StyledTypography";
import { mq } from "../../../globals/utils";

const StyledParametersList = styled.div`
  box-shadow: ${ELEVATION.l_0};
  border-radius: 8px;
  background-color: ${COLORS.white};
  padding: 32px 24px;
  ul {
    li {
      word-break: break-word;
    }
  }
  .parameterImg {
    padding-bottom: 24px;
  }
  ${mq.between("md", "lg")} {
    padding: 16px;
  }
`;

const IndivCard = ({listItem}) => {
  return (
    <StyledParametersList>
      <div className="parameterImg">
        <img src={listItem.imgsrc} alt={listItem.title} />
      </div>
      <StyledDesc1 fontWeight="font_600" paddingBottom="pb16">{listItem.title}</StyledDesc1>
      <StyledDesc2>{listItem.desc}</StyledDesc2>
    </StyledParametersList>
  );
};

export default IndivCard;
