import React from "react";
// import { Link } from "gatsby";
import styled from "styled-components";
// import { CopyBlock, dracula } from "react-code-blocks";
//
import { COLORS } from "../../../globals/designSystem";
import { StyledSubTitle1, StyledSubTitle2, StyledDesc1 } from "../../shared/StyledTypography";
import StyledSectionWrapper from "../../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../../shared/StyledContainerWrapper";
import { textCenter, removePaddBottom } from "../../shared/CommonStyled";
import { mq } from "../../../globals/utils";

const StyledProblemWebCachingWrapper = styled.div`
  padding-bottom: 100px;
  border-bottom: 1px solid ${COLORS.grey_30};
  .problemCachingListWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 100px;
  }
  ${mq.between("md", "lg")} {
    padding-bottom: 70px;
    .problemCachingListWrapper {
      grid-gap: 50px;
    }
  }
  ${mq.below.md} {
    padding-bottom: 50px;
    .mobileAlign {
      text-align: left;
      padding-bottom: 24px;
    }
    .problemCachingListWrapper {
      grid-template-columns: 1fr;
      grid-gap: 50px;
    }
  }
`;

const ProblemWebCaching = ({title, list}) => {
  return (
    <StyledSectionWrapper css={removePaddBottom}>
      <StyledContainerWrapper>
        <StyledProblemWebCachingWrapper>
          <StyledSubTitle1 className="mobileAlign" paddingBottom="pb56" css={textCenter}>{title}</StyledSubTitle1>
          <div className="problemCachingListWrapper">
            {
              list.map((listItem, index) => (
                <div key={index} className="problemCachingList">
                  <StyledSubTitle2 paddingBottom="pb16">{listItem.title}</StyledSubTitle2>
                  <StyledDesc1>{listItem.desc}</StyledDesc1>
                </div>
              ))
            }
          </div>
        </StyledProblemWebCachingWrapper>
      </StyledContainerWrapper>
    </StyledSectionWrapper>
  );
};

export default ProblemWebCaching;
