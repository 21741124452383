import React from "react";
import Helmet from "react-helmet";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import CachingIndex from "../../components/solutions/caching/CachingIndex";

const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/caching-graphql.png",
};

const canonicalUrl = "https://hasura.io/graphql/caching/";

const faqSchema = [
  {
    "@type": "Question",
    name: "What is GraphQL Caching?",
    acceptedAnswer: {
      "@type": "Answer",
      text:
        "Caching in GraphQL can be implemented on both the server side and client side. On the server side, caching a GraphQL query would typically involve storing the results in a low-latency document storage database like Redis. Hasura lets you cache GraphQL queries to improve application performance.",
    },
  },
  {
    "@type": "Question",
    name: "How is caching in GraphQL different from REST API?",
    acceptedAnswer: {
      "@type": "Answer",
      text:
        "Caching in traditional REST APIs use the HTTP methods and endpoints to cache the APIs server side. Since GraphQL is typically a HTTP POST request, it needs to be handled differently.",
    },
  },
  {
    "@type": "Question",
    name: "What kind of caching does Hasura support?",
    acceptedAnswer: {
      "@type": "Answer",
      text:
        "Hasura Cloud supports GraphQL query response caching. Read more about Query Response Caching",
    },
  },
  {
    "@type": "Question",
    name: "How to clear a cache for the GraphQL request?",
    acceptedAnswer: {
      "@type": "Answer",
      text:
        "You can make use of the “refresh” argument in the @cached directive to indicate that the old cache needs to be cleared and refreshed.",
    },
  },
  {
    "@type": "Question",
    name: "How long can data be stored in a cache?",
    acceptedAnswer: {
      "@type": "Answer",
      text:
        "Hasura supports cache lifetime of upto 300 seconds. You can specify this time using the “ttl” argument of the @cached directive.",
    },
  },
];

const Caching = ({ location }) => (
  <Layout location={location}>
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "http://schema.org/",
          "@type": "FAQPage",
          mainEntity: faqSchema,
        })}
      </script>
    </Helmet>
    <Seo
      title="GraphQL Caching on the Server for existing APIs"
      description="Add Caching to existing GraphQL APIs and get faster response times with Hasura Cloud."
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    <CachingIndex />
  </Layout>
);

export default Caching;
