import React from "react";
import styled from "styled-components";
import { StyledSubTitle1 } from "../../shared/StyledTypography";
import StyledSectionWrapper from "../../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../../shared/StyledContainerWrapper";
import { mq } from "../../../globals/utils";

import IndivCard from "../common/IndivCard";

const StyledparametersCachingWrapper = styled.div`
  .parametersListWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 40px;
    padding-top: 80px;
  }
  ${mq.between("md", "lg")} {
    .parametersListWrapper {
      padding-top: 50px;
      grid-gap: 16px;
    }
  }
  ${mq.below.md} {
    .parametersListWrapper {
      padding-top: 24px;
      grid-template-columns: 1fr;
      grid-gap: 24px;
    }
  }
`;
const ParametersCaching = ({title, listState}) => {
  const parameter = listState.map((listItem, index) => (
    <IndivCard key={index} listItem={listItem} />
  ))
  return (
    <StyledSectionWrapper>
      <StyledContainerWrapper>
        <StyledparametersCachingWrapper>
          <StyledSubTitle1>{title}</StyledSubTitle1>
          <div className="parametersListWrapper">
            {parameter}
          </div>
        </StyledparametersCachingWrapper>
      </StyledContainerWrapper>
    </StyledSectionWrapper>
  );
};

export default ParametersCaching;
