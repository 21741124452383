import React from "react";
import styled from "styled-components";

import { StyledSubTitle1, StyledDesc1 } from "../../shared/StyledTypography";
import StyledSectionWrapper from "../../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../../shared/StyledContainerWrapper";
import { ELEVATION } from "../../../globals/designSystem";
import { mq } from "../../../globals/utils";
import { removePaddBottom } from "../../shared/CommonStyled";

import tickCircle from "../../homepagenew/images/tick-circle.svg";

const StyledCachingWithHasuraWrapper = styled.div`
  display: grid;
  grid-template-columns: 4fr 6fr;
  grid-gap: 100px;
  .pl-100 {
    padding-left: 100px;
  }
  ul {
    li {
      img {
        margin-top: 4px;
      }
    }
  }
  .solutionCachingImg {
    padding-top: 70px;
    text-align: center;
    img {
      width: 100%;
      display: inline-block;
      border-radius: 4px;
      box-shadow: ${ELEVATION.l_0};
    }
  }
  .removeTopPadd {
    padding-top: 0px;
  }
  ${mq.between("md", "lg")} {
    grid-gap: 50px;
    .pl-100 {
      padding-left: 0px;
    }
  }
  ${mq.below.md} {
    grid-template-columns: 1fr;
    grid-gap: 50px;
    .pl-100 {
      padding-left: 0px;
    }
    .solutionCachingImg {
      padding-top: 0;
    }
    .mobileAlign {
      padding-bottom: 24px;
    }
  }
`;

const CachingWithHasura = ({title, subTitle, list, desc, rightDesc, imgsrc, pagePaddRemove, leftPaddRemove, topPaddRemove}) => {
  return (
    <StyledSectionWrapper css={pagePaddRemove ? null : removePaddBottom}>
      <StyledContainerWrapper>
        <StyledCachingWithHasuraWrapper>
          <div>
            <StyledSubTitle1 paddingBottom="pb40" className="mobileAlign">{title}</StyledSubTitle1>
            {
              subTitle ? (
                <StyledDesc1 fontWeight="font_600" paddingBottom="pb32">{subTitle}</StyledDesc1>
              ) : null
            }
            {list ? (
              <StyledDesc1 paddingBottom="pb32">
                <ul>
                {
                  list.map((listItem, index) => (
                    <li key={index}><img className="leftIcon" src={tickCircle} alt="Tick" />{listItem}</li>
                  ))
                }
                </ul>
              </StyledDesc1>
            ) : null}

            <StyledDesc1>{desc}</StyledDesc1>
          </div>
          <div className={leftPaddRemove ? "" : "pl-100"}>
            {rightDesc ? (
              <StyledDesc1>{rightDesc}</StyledDesc1>
            ) : null}
            {
              imgsrc ? (
                <div className={"solutionCachingImg" + ((topPaddRemove) ? " removeTopPadd" : "")}>
                  <img src={imgsrc} alt={title} />
                </div>
              ) : null
            }
          </div>
        </StyledCachingWithHasuraWrapper>
      </StyledContainerWrapper>
    </StyledSectionWrapper>
  );
};

export default CachingWithHasura;
