import React, { Fragment, useRef, useMemo } from "react";
import styled from "styled-components";

import SubNav from "../common/SubNav";
import TopBanner from "../common/TopBanner";
import ProblemWebCaching from "../common/ProblemWebCaching";
import ParametersCaching from "./ParametersCaching";
import CachingWithHasura from "../common/CachingWithHasura";
import Features from "../../database/sqlServer/Features";
import StyledSectionWrapper from "../../shared/StyledSectionWrapper";
import GetStarted from "../common/GetStarted";
import Faq from "../common/Faq";
import { StyledDesc1 } from "../../shared/StyledTypography";
import { COLORS } from "../../../globals/designSystem";
import { removePaddTop } from "../../shared/CommonStyled";

import alert from "../images/alert.svg";
import withCaching from "../images/with-caching.png";
import visible from "../images/visible-icon.svg";
import database from "../images/database-icon.svg";
import graphql from "../images/graphql-icon.svg";
import query from "../images/query-icon.svg";
import browser from "../images/browser-icon.svg";
import cacheAuthenticated from "../images/cache-authenticated.png";
import cachingDatabaseLayer from "../images/caching-database-layer.png";
import cacheAnyRemote from "../images/cache-any-remote.png";
import restApisTraditionalCaching from "../images/rest-apis-traditional-caching.png";
import server from "../images/server.svg";
import star from "../images/star.svg";
import watchLater from "../images/watch-later.svg";

const topBannerState = {
  title: "Add Caching to existing GraphQL APIs",
  subTitle: "Get faster response times with Hasura Cloud Caching",
  btnContent: "Get Started with Caching",
  btnLink: "https://cloud.hasura.io/signup?pg=caching&plcmt=body&cta=get-started-with-caching&tech=default",
  note: "Caching is now available on the free tier",
  list: ["Automatic caching of authenticated data", "Query caching at the database level", "Blazing fast performance"],
  codeBlock: true,
}

const cachingWithHasuraListState = [
  "The query can be from any external GraphQL server added via Remote Schemas",
  "Authenticated queries can be cached by Hasura as long as session variables are not used",
  "Hasura caches response JSON upto 100KB in size.",
]

const problemCachingState = [
  {
    title: "Caching is hard",
    desc: <span>GraphQL is transport agnostic. Most server implementations in GraphQL, including Hasura use the HTTP REST API style for making requests.<br/><br/>
      What does it impact? GraphQL cannot leverage existing patterns for REST APIs. All requests are HTTP POST requests and they cannot be cached. GET requests have limitations around query string size.
    </span>
  },
  {
    title: "What’s slowing down your queries?",
    desc: <span>Typically, there could be latency and slow response times for GraphQL queries due to<br/><br/>
      <ul>
        <li><img className="leftIcon" src={alert} alt="Alert" />Size of the response</li>
        <li><img className="leftIcon" src={alert} alt="Alert" />Location of the server</li>
        <li><img className="leftIcon" src={alert} alt="Alert" />No. of concurrent API calls</li>
      </ul>
    </span>
  },
]

const cachingListState = [
  {
    id: "cache-authenticated-data",
    imgSrc: visible,
    linkContent: "Cache authenticated data",
    title: "Cache authenticated data",
    desc: <span>
      <ul className="discUl">
        <li className="disc">Hasura has information about the data models of your application and the authorization rules to offer an end to end caching solution</li>
        <li className="disc">Cache shared data with invalidation logic</li>
        <li className="disc">Automatic cache invalidation with ttl config for refreshing stale data</li>
      </ul>
    </span>,
    panelImg: cacheAuthenticated,
  },
  {
    id: "caching-at-the-database-layer",
    imgSrc: database,
    linkContent: "Caching at the database layer",
    title: "Caching at the database layer",
    desc: <span>
      Hasura uses PostgreSQL prepared statements where parsing is skipped and only planning and execution takes place.<br/><br/>
      <ul className="discUl">
        <li className="disc">GraphQL query plans get cached at Hasura: This means that the code for SQL generation, adding authorization rules etc doesn't need to run repeatedly.</li>
        <li className="disc">SQL query plans get cached by Postgres with prepared statements:  Given a SQL query, Postgres needs to parse, validate and plan it's own execution for actually fetching data. A prepared statement executes significantly faster, because the entire execution plan is already "prepared" and cached!</li>
      </ul>
    </span>,
    panelImg: cachingDatabaseLayer,
  },
  {
    id: "cache-any-remote-graphql-api",
    imgSrc: graphql,
    linkContent: "Cache any Remote GraphQL API",
    title: "Cache any Remote GraphQL API",
    desc: <span>
      Hasura Cloud supports query response caching for Remote Schemas. This lets you cache any other custom GraphQL API that you have written or any external GraphQL API from services such as GitHub, Contentful, GraphCMS and so on.
    </span>,
    panelImg: cacheAnyRemote,
  },
  {
    id: "rest-apis-for-traditional-caching",
    imgSrc: query,
    linkContent: "Generate REST APIs for traditional caching",
    title: "Generate REST APIs for traditional caching",
    desc: <span>
      Hasura lets you generate REST APIs from GraphQL. Now these REST APIs can be cached using typical HTTP caching tools and techniques.<br/><br/>
      <ul className="discUl">
        <li className="disc">Cache GET endpoints using CDNs</li>
        <li className="disc">Use HTTP status code for valid responses</li>
      </ul>
    </span>,
    panelImg: restApisTraditionalCaching,
  },
  {
    id: "client-side-caching",
    imgSrc: browser,
    linkContent: "Client Side Caching",
    title: "Client Side Caching",
    desc: <span>
      <ul className="discUl">
        <li className="disc">Hasura GraphQL Endpoint works with popular GraphQL Clients like Apollo Client and urql and their normalized cache</li>
        <li className="disc">Optimistic UI updates after making a mutation request. Read more <a href="https://hasura.io/blog/optimistic-ui-and-clobbering/">Optimistic UI and Clobbering</a></li>
      </ul>
    </span>,
    // panelImg: dataFederation,
  },
]

const parametersState = [
  {
    imgsrc: server,
    title: "Server side caching",
    desc: <span>Typically gateway caches are used to check if the data is still up to date in the cache to avoid additional round trips.<br/><br/>The advantage is that Hasura doubles up as your server side cache. </span>,
  },
  {
    imgsrc: browser,
    title: "Client side caching",
    desc: "Client side caches at the browser level use HTTP caching to avoid refetching data that is still fresh. This is set by response headers by the server for the request. GraphQL Clients cache data with an in-memory cache.",
  },
  {
    imgsrc: star,
    title: "Cache freshness",
    desc: "Control the number of times a resource should be considered fresh with response headers like Cache-Control. This is typically well suited for data that doesn’t change in real time or as often as up to 5 mins.",
  },
  {
    imgsrc: watchLater,
    title: "Cache expiry",
    desc: "Control cache lifetime with Hasura using the “ttl” argument on the `@cached` directive.",
  },
]

const faqAllState = [
  {
    id: "what-is-graphql-caching",
    question: "What is GraphQL Caching?",
    answer: <StyledDesc1>
      Caching in GraphQL can be implemented on both the server side and client side. On the server side, caching a GraphQL query would typically involve storing the results in a low-latency document storage database like Redis. Hasura lets you cache GraphQL queries to improve application performance.
    </StyledDesc1>,
  },
  {
    id: "how-is-caching-in-graphql-different-from-rest-api",
    question: "How is caching in GraphQL different from REST API?",
    answer: <StyledDesc1>
      Caching in traditional REST APIs use the HTTP methods and endpoints to cache the APIs server side. Since GraphQL is typically a HTTP POST request, it needs to be handled differently.
    </StyledDesc1>,
  },
  {
    id: "what-kind-of-caching-does-hasura-support",
    question: "What kind of caching does Hasura support?",
    answer: <StyledDesc1>
      Hasura Cloud supports GraphQL query response caching. Read more about <a href="https://hasura.io/docs/latest/graphql/cloud/response-caching.html" target="_blank" rel="noopener noreferrer">Query Response Caching</a>
    </StyledDesc1>,
  },
  {
    id: "how-to-clear-a-cache-for-the-graphql-request",
    question: "How to clear a cache for the GraphQL request?",
    answer: <StyledDesc1>
      You can make use of the “refresh” argument in the @cached directive to indicate that the old cache needs to be cleared and refreshed.
    </StyledDesc1>,
  },
  {
    id: "how-long-can-data-be-stored-in-a-cache",
    question: "How long can data be stored in a cache?",
    answer: <StyledDesc1>
      Hasura supports cache lifetime of upto 300 seconds. You can specify this time using the “ttl” argument of the @cached directive.
    </StyledDesc1>,
  },
]

const StyledSectionWrapperLightGray = styled(StyledSectionWrapper)`
  background-color: ${COLORS.grey_4};
`;


const CachingIndex = () => {
  const theProblemRef = useRef(null);
  const cachingWithHasuraRef = useRef(null);
  const featuresRef = useRef(null);
  const cacheAuthenticatedDataRef = useRef(null);
  const cachingAtTheDatabaseLayerRef = useRef(null);
  const cacheAnyRemoteGraphQLApiRef = useRef(null);
  const restApisForTraditionalCachingRef = useRef(null);
  const clientSideCachingRef = useRef(null);

  const sectionRefs = useMemo(
    () => [
      { name: "The Problem", section: "the-problem", ref: theProblemRef },
      { name: "Caching With Hasura", section: "caching-with-hasura", ref: cachingWithHasuraRef },
      { name: "Features", section: "features", ref: featuresRef },
    ],
    []
  );

  const cachingSection = {
    "cache-authenticated-data": { section: "cache-authenticated-data", ref: cacheAuthenticatedDataRef },
    "caching-at-the-database-layer": { section: "caching-at-the-database-layer", ref: cachingAtTheDatabaseLayerRef },
    "cache-any-remote-graphql-api": { section: "cache-any-remote-graphql-api", ref: cacheAnyRemoteGraphQLApiRef },
    "rest-apis-for-traditional-caching": { section: "rest-apis-for-traditional-caching", ref: restApisForTraditionalCachingRef },
    "client-side-caching": { section: "client-side-caching", ref: clientSideCachingRef },
  }

  return (
    <>
      <SubNav
        sectionRefs={sectionRefs}
        pageName="Caching"
        pagePath="/graphql/caching/"
        currentDropDownMenu="solutions"
        subNavBtnLink="https://cloud.hasura.io/signup?pg=caching&plcmt=sub-header&cta=get-started-now&tech=default"
      />
      <TopBanner topBannerState={topBannerState}/>
      <div id="the-problem" ref={theProblemRef}>
        <ProblemWebCaching
          title="The problem with web caching"
          list={problemCachingState}
        />
      </div>
      <div id="caching-with-hasura" ref={cachingWithHasuraRef}>
        <CachingWithHasura
          title="How does caching work with Hasura?"
          subTitle="A GraphQL query’s response can be cached by Hasura for various use cases"
          list={cachingWithHasuraListState}
          desc="Cached responses are stored for a period of time in a LRU (least-recently used) cache, and removed from the cache as needed based on usage."
          rightDesc="Hasura has metadata about the data models across data sources, and the authorization rules at the application level. This helps Hasura provide end-to-end application caching."
          imgsrc={withCaching}
        />
        <ParametersCaching
          title="Parameters involved in GraphQL caching"
          listState={parametersState}
        />
      </div>
      {/* <CaseStudyWrapper /> */}
      <div  id="features" ref={featuresRef}>
        <Features
          title="Features"
          sections = {cachingSection}
          tabListState = {cachingListState}
        />
      </div>
      <StyledSectionWrapperLightGray css={removePaddTop}>
        <GetStarted
          title="Ready to get started with caching?"
          btnContent="Start with Hasura Cloud"
          btnLink="https://cloud.hasura.io/signup?pg=caching&plcmt=body&cta=start-with-hasura-cloud&tech=default"
        />
      </StyledSectionWrapperLightGray>
      <Faq
        title="Frequently Asked Questions"
        faqAllState={ faqAllState }
      />
    </>
  );
};

export default CachingIndex;
